import { createContext, useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "./authContext";
import useWebSocket from "react-use-websocket";


const SocketContext = createContext();

export default SocketContext;

export const SocketContextProvider = ({ children }) => {
    const didUnmount = useRef(false)
    const [updatedSongonId, setUpdatedSongonId] = useState(0)
    const { readyState, lastJsonMessage, lastMessage, sendJsonMessage, sendMessage, } = useWebSocket(`${process.env.REACT_APP_SERVER_URL_WS}/lottery/`,
        {
            // share: true,
            shouldReconnect: (closeEvent) => { return didUnmount.current === false; },
            reconnectAttempts: 10,
            reconnectInterval: 1000,
            // heartbeat
            onReconnectStop: () => handleReconnectStop()
        }
    )
    useEffect(
        () => {
            if (lastJsonMessage) {
                if (lastJsonMessage?.songon_id) {
                    setUpdatedSongonId(lastJsonMessage?.songon_id)
                }
            }
        },
        [lastJsonMessage]
    )
    function handleReconnectStop() {
        console.log('User socket auto connection stopped')
    }
    const contextValues = {
        readyState, updatedSongonId
    }
    return <SocketContext.Provider value={contextValues}>
        {children}
    </SocketContext.Provider>
}
