import React from "react";

import ImageUpload from "components/main/Form/InputTypes/ImageUpload";
import CForm from "components/main/Form";

import { testValidate2 } from "utils/validate";

import './style.css'

const TestForm = () => {

    const apiError =
    {
        success: false,
        data: [],
        errors: {
            TestNumber: ["A valid number is required."],
            TestEmail: ["Энэ бол тестийн алдааны msg", "2 дахь алдааны msg"],
        },
        statusCode: 400,
    };

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const onSubmitFormData = async () =>
    {
        const formData = new FormData()
        formData.append()

        const config =
        {
            headers: { 'content-type': 'multipart/form-data' }
        }

        // some api request

    }

    const fakeInputs = {
        inputs: [
            {
                inputType: "input",
                registerName: "TestEmail",
                type: "email",
                placeholder: "И-мэйлээ бичнэ үү?",
                disabled: false,
                readOnly: false,
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                label: {
                    text: "Энэ бол и-мэйл",
                    empty: false,
                },
            },
            {
                // TODO:  form ийн функүүд ирдэг маягаар хийх
                // component: <ImageUpload id='test' />,
            },
            {
                inputType: "input",
                registerName: "TestPassword",
                type: "password",
                placeholder: "password бичнэ үү?",
                disabled: false,
                readOnly: false,
                inputGroup: {
                    end: <i className="fa-brands fa-accusoft"></i>,
                },
                label: {
                    text: "Ene bol email",
                    empty: false,
                },
            },
            {
                inputType: "input",
                registerName: "TestNumber",
                type: "number",
                placeholder: "number бичнэ үү?",
                disabled: false,
                readOnly: false,
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                    end: <i className="fa-brands fa-accusoft"></i>,
                },
                label: {
                    text: "Ene bol number",
                    empty: false,
                },
            },
            {
                inputType: "datePicker",
                registerName: "testDatePicker",
                placeholder: "hugatsaa songono vv?",
                label: {
                    text: "Ene bol datepicker",
                    empty: false,
                },
                helperText: "hey"
            },
            {
                inputType: "datePicker",
                registerName: "testDatePickerRange",
                placeholder: "хугацааны интервал сонгоно уу",
                selectsRange: true,
                label: {
                    text: "Ene bol datepicker",
                    empty: false,
                },
            },
            {
                inputType: "select",
                registerName: "TestSelectMulti",
                isMulti: true,
                type: "number",
                placeholder: "select oruulna uu?",
                disabled: false,
                readOnly: false,
                label: {
                    text: "Ene bol select",
                    empty: false,
                },
                options: [
                    { value: "cho123123123colate", label: "321321" },
                    { value: "str123123123awberry", label: "St123123rawberry" },
                    { value: "van123123123illa", label: "Vanil123123la" },
                ],
            },
            {
                inputType: "select",
                registerName: "TestSelect",
                isMulti: false,
                type: "number",
                placeholder: "select oruulna uu?",
                disabled: false,
                readOnly: false,
                options: [
                    { value: "chocolate", label: "Chocolate" },
                    { value: "strawberry", label: "Strawberry" },
                    { value: "vanilla", label: "Vanilla" },
                ],
                label: {
                    text: "Ene bol select",
                    empty: false,
                },
            },
            {
                inputType: "imageUpload",
                registerName: "imageUpload",
            },
            {
                inputType: "fileUpload",
                registerName: "fileUpload",
                fileType: 'json'
            },
            {
                inputType: "radio",
                type: "radio",
                registerName: "TestRadio",
                disabled: false,
                readOnly: false,
                options: [
                    { value: true, label: "YES" },
                    { value: false, label: "NO", defaultChecked: true },
                ],
                label: {
                    text: "Ene bol radio",
                    empty: false,
                },
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "TestCheckbox",
                disabled: false,
                readOnly: false,
                options: [
                    { label: "YES", defaultChecked: true },
                ],
                label: {
                    text: "Ene bol radio",
                    empty: false,
                },
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                alert(JSON.stringify(data))
                // await sleep(2000);
                // return apiError
            },
            title: "Илгээх or something",
        },
        validate: {
            validateMode: "all",
            yupValidate: testValidate2,
        },
    };

    return (
        <div style={{ height: "calc(calc(100vh - calc(100vh - 100%)) - 100px)", width: "99vw", padding: '40px' }}>
            <CForm form={fakeInputs} inputValues={{  }} />
        </div>
    );
};

export default TestForm;
