import { Circle as CircleStyle, Fill, Stroke, Style, Icon, RegularShape } from 'ol/style';
import Text from 'ol/style/Text';
import * as olColor from 'ol/color'
import Progress from './loadMap';

import { getRedGreenColor, greenToRedColor50, priceStyle } from "utils/color";

import { SELECT_FILL_COLOR } from './conts';
import { MAIN_DARKBLUE_COLOR } from 'utils/consts/colors'
import { quantityFormat } from 'utils/format';
import { HEREGJILT_COLOR } from 'utils/consts';

/** сайтын feature ийн үндсэн загвар */
export const styles = {
    'MultiPolygon': new Style({
        stroke: new Stroke({
            color: 'red',
            width: 1,
        }),
        fill: new Fill({
            color: 'rgba(255, 255, 0, 0.1)',
        }),
        text: new Text(
            {
                text: "",
                font: '12px Calibri,sans-serif',
                fill: new Fill({ color: '#000' }),
                stroke: new Stroke(
                    {
                        color: '#fff',
                        width: 2
                    }
                )
            }
        ),
    }),
    'Polygon': new Style({
        stroke: new Stroke({
            color: 'red',
            width: 1,
        }),
        fill: new Fill({
            color: 'rgba(255, 255, 0, 0.1)',
        }),
        text: new Text(
            {
                text: "",
                font: '12px Calibri,sans-serif',
                fill: new Fill({ color: '#000' }),
                stroke: new Stroke(
                    {
                        color: '#fff',
                        width: 2
                    }
                )
            }
        ),
    }),
    "Point": new Style(
        {
            image: new CircleStyle(
                {
                    radius: 10,
                    stroke: new Stroke(
                        {
                            color: '#fff',
                        }
                    ),
                    fill: new Fill(
                        {
                            color: '#3399CC',
                        }
                    ),
                }
            ),
            text: new Text(
                {
                    text: "",
                    font: '12px Calibri,sans-serif',
                    fill: new Fill({ color: '#000' }),
                    stroke: new Stroke(
                        {
                            color: '#fff',
                            width: 2
                        }
                    )
                }
            ),
        }
    )
};


export const styleOneApartment = (feature) =>
{
    var style = styles[feature.getGeometry().getType()]
    let clonedStyle = style.clone()
    clonedStyle.getFill().setColor('rgba(1, 145, 200, 1)');
    clonedStyle.setText( new Text(
        {
            text: "TEST",
            font: '12px Calibri,sans-serif',
            fill: new Fill({ color: '#000' }),
            stroke: new Stroke(
                {
                    color: '#fff',
                    width: 2
                }
            )
        }
    ))
    return clonedStyle
}

/**
 * Сайтын layer ийн үндсэн загвар
 * @param {*} feature
 */
export const styleFunction = function (feature, resolution, showText, someText)
{

    const apartId = feature.get("apartment_id")
    var text = feature.getProperties().Text
    var style = styles[feature.getGeometry().getType()]
    style = style.clone()
    if (text && text.toLowerCase().includes('гадна')) {
        style.getFill().setColor('rgba(28, 28, 28, 0.2)');
    }
    if (text && text.toLowerCase().includes('барилга')) {
        let color = "rgba(255, 0, 0, 1)"
        if (apartId)
        {
            color = "blue"
            style.setText(
                new Text(
                    {
                        text: feature.get("apartment_name") ? feature.get("apartment_name") : "",
                        font: '12px Calibri,sans-serif',
                        fill: new Fill({ color: '#000' }),
                        stroke: new Stroke(
                            {
                                color: '#fff',
                                width: 2
                            }
                        )
                    }
                )
            )
            /** tuluwluguu heregjltees hamaarch ungu soligdono */
            if (Object.keys(feature.get("cproject") ?? {}).length > 0)
            {
                color = HEREGJILT_COLOR[feature.get("cproject")?.tuluwluguu_heregjilt] ?? color
            }
        }
        style.getFill().setColor(color);
    }
    if (text && text.toLowerCase().includes('ногоон')) {
        style.getFill().setColor('rgba(0, 255, 0, 0.5)');
    }
    else if (text && text.toLowerCase().includes('спорт')) {
        style.getFill().setColor('rgba(250, 138, 10, 0.8)');
    }
    else if (text && text.toLowerCase().includes('зам')) {
        style.getFill().setColor('rgba(107, 65, 17, 0.6)');
    }
    else if (text && text.toLowerCase().includes('талбар')) {
        style.getFill().setColor('rgba(0, 0, 0, 0.2)');
    }
    else {
        if (style.getFill())
        {
            style.getFill().setColor('rgba(0, 0, 0, 0.1)');
        }
    }

    /** Тухайн feature ийн нэрийг харуулах нь */
    if (showText === true)
    {
        const realText = someText ? someText : feature.get("text")
        style.getText().setText(realText)
    }

    return style
};

/**
 * төслийн хэрэгжилтийн загвар
 */
export const projectStepStyle = (feature, resolution, showText, someText) =>
{

    let style = styles[feature.getGeometry().getType()]
    // styles['Point'].getImage()
    style = style.clone()

    const data = feature.get("data")
    let color = HEREGJILT_COLOR[data.tuluwluguu_heregjilt]
    if (feature.getGeometry().getType().includes("Point"))
    {
        style.getImage().getFill().setColor(color)
    }
    else {
        style.getFill().setColor(color);
    }

    const realText = someText ? someText : feature.get("text")
    if (realText)
    {
        style.getText().setText(realText)
    }

    return style
}

/** Барилгын feature дээр харуулах style */
export const styleOfBuilding = ({ name, isRent }) =>
{
    const buildingStyle = new Style(
        {
            fill: new Fill(
                {
                    color: isRent ? "green" : 'red', // polygon ий доторхийг битүүлж будаж байгаа өнгө
                }
            ),
            stroke: new Stroke({ color: isRent ? "purple" : 'blue' }), // polygon ий хүрээний өнгө
            text: new Text(
                {
                    text: name || "",
                    font: '12px Calibri,sans-serif',
                    fill: new Fill({ color: '#000' }),
                    stroke: new Stroke(
                        {
                            color: '#fff',
                            width: 2
                        }
                    )
                }
            )
        }
    );
    return buildingStyle
}

const auStrokeColor = 'rgb(14,71,136)'
const auFillColor = 'transparent'
const auHoverStrokeColor = "#fcad03"
/** Засаг захиргаа нэгжийн style */
export const styleOfAu = (feature, resolution) =>
{

    const auStyle = new Style({
                        stroke: new Stroke({
                            color: auStrokeColor,
                            width: 3,
                        }),
                        fill: new Fill({
                            color: auFillColor,
                        }),
                    })
    return auStyle
}

export const styleOfCadaster = (feature, resolution) =>
{
    return new Style({
        stroke: new Stroke({
            color: "#2eb356",
            width: 3,
        }),
        fill: new Fill({
            color: auFillColor,
        }),
        zIndex: 1,
    })
}

export const activeCadaster = (feature, resolution) =>
{
    return new Style({
        stroke: new Stroke({
            color: auStrokeColor,
            width: 5,
        }),
        fill: new Fill({
            color: "#2eb356",
        }),
        zIndex: 1,
    })
}

/** Засаг захиргаа нэгжийн style */
export const styleOfAuColor = (fillColor=auFillColor) =>
{

    const auStyle = new Style({
                        stroke: new Stroke({
                            color: auStrokeColor,
                            width: 3,
                        }),
                        fill: new Fill({
                            color: fillColor,
                        }),
                    })
    return auStyle
}

/** Засаг захиргаа нэгжийн style */
export const selectedAuColor = () =>
{

    const auStyle = new Style({
                        stroke: new Stroke({
                            color: "red",
                            width: 5,
                        }),
                        fill: new Fill({
                            color: auFillColor,
                        }),
                    })
    return auStyle
}

/** Засаг захиргаа нэгжийн style */
export const styleOfAuHover = (feature) =>
{
    if(!feature.get('cMap-style'))
    {
        const auStyle = new Style({
            stroke: new Stroke({
                color: auHoverStrokeColor,
                width: 3,
            }),
            fill: new Fill({
                color: auFillColor,
            }),
            zIndex: 100
        })
        return auStyle
    }

    let style = feature.get('cMap-style').clone()
    const color = style.getFill()?.getColor()
    var hexColor = olColor.asArray(color);
    hexColor[3] = hexColor[3] === 0.6 ? hexColor[3] : hexColor[3] * 2 //  hex color дээр opacity нэмж байна

    // style.setZIndex(101)
    style.getFill().setColor(hexColor)
    style.getStroke().setColor(auHoverStrokeColor)
    style.setZIndex(style.getZIndex() + 1)

    return style
}

/** Хуучин барилгын харуулах style */
export const styleOfAparts = ({ name }) =>
{
    const buildingStyle = new Style(
        {
            fill: new Fill(
                {
                    color: "green"
                }
            ),
            stroke: new Stroke({ color: 'blue' }),
            text: new Text(
                {
                    text: name || "",
                    font: '12px Calibri,sans-serif',
                    fill: new Fill({ color: '#000' }),
                    stroke: new Stroke(
                        {
                            color: '#fff',
                            width: 2
                        }
                    )
                }
            )
        }
    );
    return buildingStyle
}

const notClusterStyle = (feature) =>
{
    let type = feature.getGeometry().getType()
    const typeStyle = {
        "MultiPolygon": new Style({
            stroke: new Stroke({
                color: MAIN_DARKBLUE_COLOR,
                width: 3,
            }),
        })
    }
    return typeStyle[type]
}

/** хил заагийг харуулахад хэрэгтэй */
export const styleOfBoundary = (feature) =>
{
    return notClusterStyle(feature)
}

export const radiusCircle = () =>
{
    var style = new Style({
        fill: new Fill({
            color: 'rgba(20, 100, 240, 0.3)'
        }),
        stroke: new Stroke({
            width: 3,
            color: 'rgba(0, 100, 240, 0.8)'
        }),
        image: new CircleStyle({
            fill: new Fill({
                color: 'rgba(55, 200, 150, 0.5)'
            }),
            stroke: new Stroke({
                width: 10,
                color: 'rgba(55, 200, 150, 0.8)'
            }),
        }),
    });
    return style
}

export const styleClusterPoint = (feature) =>
{
    /** тухайн нэг cluster дээр харагдаж байгаа feature үүд */
    const features = feature.get('features')

    if (!features)
    {
        return
    }

    /** түрээсийн байр байгаа эсэх */
    let has_rent = false

    /** нийт барилгын тоог авах нь  feature ээс */
    const count = features.reduce(
        (prevCount, currentFeature) =>
        {
            /** түрээсийн байр байгаа шалгаж байна */
            if (!has_rent)
            {
                has_rent = currentFeature.get("in_rent")
            }

            // prevCount += currentFeature.get("residence_count")
            // return prevCount
        },
        0
    )

    const markerHeight = 75
    const markerUrl = process.env.PUBLIC_URL + (has_rent ? "/images/map/rent-marker.png" : "/images/map/ap-marker.png")

    const style = new Style(
        {
            image: new Icon(
                {
                    anchor: [0.5, 1],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    src: markerUrl,
                    size: [50, 68],
                }
            ),
            // text: new Text(
            //     {
            //         offsetY: -(75 - 26 - 11 - 6 - 13),
            //         textAlign: "center",
            //         text: count.toString(),
            //         font: "13px",
            //         fill: new Fill(
            //             {
            //                 color: "#fff",
            //             }
            //         ),
            //     }
            // ),
            zIndex: 1
        }
    )
    return style
}

const initSelectStyle = {
    fill: new Fill(
        {
            color: SELECT_FILL_COLOR,
        }
    ),
    stroke: new Stroke(
        {
            color: 'rgba(255, 255, 255, 0.7)',
            width: 2,
        }
    ),
}
/** select дарах үеийн үндсэн загвар */
const selectedMainStyle = new Style(initSelectStyle);

function resetSelectStyle()
{
    /** set хийгдсэн style ийг буцааж reset хийж байгаа нь */
    selectedMainStyle.setFill(new Fill(
        {
            color: SELECT_FILL_COLOR,
        }
    ))
    selectedMainStyle.setText(undefined)
    selectedMainStyle.setStroke(initSelectStyle.stroke)
}

/** select хийх үеийн style */
export function selectStyle(feature)
{
    /** feature ийн properties дотор style гэж байна уу үгүй юу шалгаад байвал уг style ийг оноох нь */
    const featureStyle = feature.get('cMap-style');
    if (featureStyle)
    {
        const featureClonedStyle = featureStyle.clone()
        let featureColor = featureClonedStyle.getFill().getColor()
        var hexColor = olColor.asArray(featureColor);
        hexColor[3] = hexColor[3] * .7 //  hex color дээр opacity нэмж байна
        const clonedStyle = selectedMainStyle.clone()
        clonedStyle.getFill().setColor(hexColor)
        clonedStyle.setText(featureClonedStyle.getText())
        return clonedStyle
    }
    /** байхгүй бол үндсэн select style ийг оноо нь */
    else {
        const geomType = feature.getGeometry().getType()
        if (geomType.includes('Point'))
        {
            return new Style(
                {
                    image: new CircleStyle(
                        {
                            radius: 10,
                            stroke: new Stroke(
                                {
                                    color: 'red',
                                    width: 3,
                                }
                            ),
                            fill: new Fill(
                                {
                                    color: '#3399CC',
                                }
                            ),
                        }
                    ),
                    text: new Text(
                        {
                            text: feature.get('text') ?? "",
                            font: '12px Calibri,sans-serif',
                            fill: new Fill({ color: '#000' }),
                            stroke: new Stroke(
                                {
                                    color: '#fff',
                                    width: 2
                                }
                            )
                        }
                    ),
                }
            )
        }
        return selectedMainStyle;
    }
}

/** home хэсгийн газрын зурган дээр select хийх үеийн style */
export function selectClusterFeatureStyle(feature, a, b)
{
    const isCluster = !!feature.get("features")
    if (!isCluster)
    {
        const style = notClusterStyle(feature)
        return style
    }
    const style = styleClusterPoint(feature)
    const clonedStyle = style.clone()
    clonedStyle.getImage().setOpacity(0.85)
    return clonedStyle
}

export function loaderWFS(extent, resolution, projection, loadId="")
{

    loadId = loadId || 'progressMap'

    const elementProgress = document.getElementById(loadId)
    const hasProgress = !!elementProgress

    let progress = null
    if (hasProgress)
    {
        progress = new Progress(elementProgress)
    }

    var getUrl = function(extent, resolution)
    {
        progress && progress.addLoading();
    };

    var xhr = new XMLHttpRequest();

    xhr.open('GET', getUrl(extent, resolution), true);
    xhr.onload = function(event) {
        progress && progress.addLoaded();
    }.bind(this);

    xhr.onerror = function(event) {
        progress && progress.addLoaded();
    }

    xhr.send();
}

export const userLocationStyle = new Style({
    image: new Icon({
        anchor: [0.5, 30 + 10],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: process.env.PUBLIC_URL + "/images/map/user-marker.png",
    }),
    zIndex: 100,
});


export const highlightStyle = new Style({
    stroke: new Stroke({
        color: 'rgba(255, 255, 255, 0.7)',
        width: 2,
    }),
});

function calcRadiusWithPrice(myfeature)
{
    let myMaxPrice = 0
    const myfeatures = myfeature.get("features") || []
    myfeatures.map(
        (ft, idx) =>
        {
            let price = ft.get("price")
            let type = ft.get("name")
            if (price)
                price = parseInt(price) || 0
            if (price > myMaxPrice)
            {
                myMaxPrice = price
                myfeature.set('my-max-price', myMaxPrice / myfeatures.length);
                myfeature.set('type', myMaxPrice / myfeatures.length);
            }
        }
    )
}


export function styleResCircle(feature, resolution, vLayer, map)
{
	calcRadiusWithPrice(feature)
    let myMaxPrice = feature.get("my-max-price")
    const price = parseFloat(Math.ceil(myMaxPrice / 1_000_000 > 8 ? 8 : myMaxPrice / 1_000_000))
    const number = price < 1.5 ? Math.floor(price) : Math.ceil(price)
	const stroke = new Stroke({color: 'black', width: 1})
	const fill = new Fill({ color: priceStyle[number].color })

	return new Style({
		image: new RegularShape({
			fill,
			stroke,
			radius: 70 / Math.SQRT2,
			radius2: 70,
			points: 4,
			angle: 0,
			scale: [0.05, priceStyle[number].height / 3 ],
			displacement: [0, 70 / Math.SQRT2],
		}),
	})
}

export const ORG_SVGS = {
    "bus-stop": `<svg style='fill: white' xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,9v3a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V9H2V6H22V9ZM2,16H22v6H20v2H15V22H9v2H4V22H2Zm15,4a1,1,0,0,0,0-2A1,1,0,0,0,17,20ZM7,20a1,1,0,0,0,0-2A1,1,0,0,0,7,20ZM9,3h6V4h6.983a2.978,2.978,0,0,0-1.8-2.531c-3.673-1.9-12.695-1.893-16.358,0A2.978,2.978,0,0,0,2.017,4H9Z"/></svg>`,
    "kindergarten": `<svg style='fill: white' xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,11.5a3.5,3.5,0,0,0-2.149-3.226,10,10,0,0,0-19.7,0,3.5,3.5,0,0,0,1.119,6.718,10.607,10.607,0,0,0,2.071,2.955,8.908,8.908,0,0,0-2.272,4.928A1,1,0,0,0,4.06,24H19.919a1,1,0,0,0,.992-1.124,8.9,8.9,0,0,0-2.261-4.918,10.622,10.622,0,0,0,2.082-2.966A3.5,3.5,0,0,0,24,11.5Zm-3.752,1.473a.993.993,0,0,0-1.117.651C18.215,16.222,15.13,19,12,19s-6.215-2.78-7.131-5.378a.994.994,0,0,0-1.117-.651A1.606,1.606,0,0,1,3.5,13a1.5,1.5,0,0,1-.27-2.972,1,1,0,0,0,.816-.878A7.966,7.966,0,0,1,8.711,2.71a3.534,3.534,0,1,0,6.578,0,7.966,7.966,0,0,1,4.665,6.44,1,1,0,0,0,.816.878A1.5,1.5,0,0,1,20.5,13,1.606,1.606,0,0,1,20.248,12.973Z"/><circle cx="9.5" cy="11.5" r="1.5"/><circle cx="14.5" cy="11.5" r="1.5"/></svg>`,
    "school": `<svg style='fill: white' xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M16,24H8a3,3,0,0,1-2.886-2.225,6.993,6.993,0,0,1,13.772,0A3,3,0,0,1,16,24ZM3,21V13.424A5,5,0,0,0,0,18v1a5,5,0,0,0,3.924,4.876A4.953,4.953,0,0,1,3,21Zm18-7.576V21a4.953,4.953,0,0,1-.924,2.876A5,5,0,0,0,24,19V18A5,5,0,0,0,21,13.424ZM19,11v6.356a8.978,8.978,0,0,0-14,0V11A6.992,6.992,0,0,1,8,5.264V4a4,4,0,0,1,8,0V5.264A6.992,6.992,0,0,1,19,11ZM10,4.3a6.927,6.927,0,0,1,4,0V4a2,2,0,0,0-4,0ZM15,10h0a1,1,0,0,0-1-1H10a1,1,0,0,0-1,1H9a1,1,0,0,0,1,1h4A1,1,0,0,0,15,10Z"/></svg>`,
    "emiin_san": `<svg style='fill: white' xmlns="http://www.w3.org/2000/svg" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" data-name="Layer 1"><path d="m19 5h-3v-1a3 3 0 0 0 -3-3h-2a3 3 0 0 0 -3 3v1h-3a5.006 5.006 0 0 0 -5 5v8a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5v-8a5.006 5.006 0 0 0 -5-5zm-9-1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4zm5 11h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 0 2z"/></svg>`,
    "hospital": `<svg style='fill: white' xmlns="http://www.w3.org/2000/svg" id="Layer_1" height="512" viewBox="0 0 24 24" width="512" data-name="Layer 1"><path d="m16 24h-8v-8h-8v-8h8v-8h8v8h8v8h-8z"/></svg>`,
}

export function apartLocationStyle(feature, resolution)
{

    var style = styles[feature.getGeometry().getType()]
    let clonedStyle = style.clone()
    const name = feature.get("cMap-name") ? feature.get("cMap-name") : ""
    const code = feature.get("cMap-code") ? feature.get("cMap-code") : ""

   var shadowStyle = new Style({
        image: new CircleStyle(
            {
                radius: 13,
                fill: new Fill(
                    {
                        color: 'red',
                    }
                ),
            },
        ),
        text: new Text(
            {
                offsetY: -(75 - 26 - 11 - 6 - 13),
                textAlign: "center",
                text: name,
                font: "13px",
                fill: new Fill(
                    {
                        color: "#000",
                    }
                ),
            }
        ),
        zIndex: 0
    });
    const imagestyle = new Style(
        {
            image: new Icon(
                {
                    color: '#fff',
                    anchor: [0.5, 0.5], // 65 гэдэг нь marker ийн нийт өндрөөс 10 ийг хасаад гарч байгаа тоо
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    src: 'data:image/svg+xml;utf8,' + ORG_SVGS[code],
                    scale: 0.03,
                }
            ),
            zIndex: 1,
        }
    )
    return [imagestyle, shadowStyle]
}

export const POPULATION_SVG = [
    "M 39.00,2.62 C 26.15,6.31 29.19,20.79 39.00,22.38 52.56,24.57 55.81,1.99 39.00,2.62 Z M 35.00,27.70 C 27.48,31.16 24.46,37.91 26.41,46.00 27.26,49.50 29.09,50.99 29.78,55.00 30.45,58.89 29.36,68.82 39.00,70.38 41.03,70.71 43.12,70.34 44.96,69.43 52.03,65.93 50.43,58.03 51.41,54.00 52.51,49.49 54.63,48.96 54.96,43.00 55.66,30.18 46.65,25.07 35.00,27.70 Z",
    "M 39.00,2.62 C 26.15,6.31 29.19,20.79 39.00,22.38 52.56,24.57 55.81,1.99 39.00,2.62 Z M 35.00,27.70 C 25.94,31.88 24.38,40.90 26.53,50.00 26.53,50.00 29.73,59.00 29.73,59.00 30.52,63.12 28.94,73.75 39.00,75.38 41.03,75.71 43.12,75.34 44.96,74.43 52.32,70.79 50.29,62.57 51.49,58.00 51.49,58.00 54.47,50.00 54.47,50.00 56.83,40.00 54.21,27.72 42.00,27.04 39.14,26.89 37.76,27.08 35.00,27.70 Z",
    "M 39.00,2.62 C 26.15,6.31 29.19,20.79 39.00,22.38 52.56,24.57 55.81,1.99 39.00,2.62 Z M 35.00,27.70 C 26.85,31.46 26.01,35.74 26.00,44.00 26.00,46.85 25.88,50.25 26.53,53.00 26.53,53.00 29.73,62.00 29.73,62.00 30.63,66.75 28.25,78.64 39.00,80.38 44.29,81.23 48.63,77.92 50.20,73.00 51.46,69.04 50.67,65.17 51.27,62.00 51.27,62.00 54.47,53.00 54.47,53.00 56.91,42.67 55.13,27.77 42.00,27.04 39.14,26.89 37.76,27.08 35.00,27.70 Z",
    "M 39.00,2.62 C 26.15,6.31 29.19,20.79 39.00,22.38 52.56,24.57 55.81,1.99 39.00,2.62 Z M 35.00,27.70 C 26.84,31.46 26.01,35.74 26.00,44.00 25.99,47.21 25.82,51.99 26.53,55.00 27.46,58.92 29.35,60.83 29.87,65.00 30.80,72.50 28.07,83.61 39.00,85.38 44.29,86.23 48.63,82.92 50.20,78.00 51.62,73.51 50.56,67.75 51.27,64.00 51.27,64.00 54.47,55.00 54.47,55.00 55.02,52.66 54.99,50.39 55.00,48.00 55.05,38.20 55.00,27.76 42.00,27.04 39.14,26.89 37.76,27.08 35.00,27.70 Z",
    "M 39.00,2.62 C 26.15,6.31 29.19,20.79 39.00,22.38 52.56,24.57 55.81,1.99 39.00,2.62 Z M 35.00,27.70 C 24.10,32.72 25.95,40.90 26.00,51.00 26.01,53.39 25.98,55.66 26.53,58.00 28.12,64.73 29.94,62.60 30.00,72.00 30.00,72.00 30.00,76.00 30.00,76.00 30.08,82.47 31.40,89.15 39.00,90.38 48.77,91.96 50.96,82.39 51.25,75.00 51.01,72.29 50.82,68.56 51.25,66.00 51.25,66.00 54.47,58.00 54.47,58.00 55.23,54.76 55.01,47.64 55.00,44.00 54.99,35.01 52.44,27.62 42.00,27.04 39.14,26.89 37.76,27.08 35.00,27.70 Z",
    "M 39.00,2.62 C 26.15,6.31 29.19,20.79 39.00,22.38 52.56,24.57 55.81,1.99 39.00,2.62 Z M 35.00,27.70 C 24.58,32.50 26.00,39.27 26.00,49.00 26.00,52.39 25.79,57.87 26.53,61.00 28.12,67.73 29.94,65.60 30.00,75.00 30.00,75.00 30.00,79.00 30.00,79.00 30.01,85.92 30.39,93.98 39.00,95.38 49.38,97.05 50.99,86.53 51.00,79.00 51.00,79.00 51.00,75.00 51.00,75.00 51.07,63.50 54.85,66.88 55.00,55.00 55.13,44.03 57.57,27.90 42.00,27.04 39.14,26.89 37.76,27.08 35.00,27.70 Z",
    "M 39.00,2.62 C 26.15,6.31 29.19,20.79 39.00,22.38 52.56,24.57 55.81,1.99 39.00,2.62 Z M 35.00,27.70 C 27.48,31.17 26.04,35.14 26.16,43.00 26.16,43.00 26.16,63.00 26.16,63.00 27.05,67.04 28.73,69.04 29.75,72.00 30.21,74.70 30.00,80.01 29.75,83.00 30.01,89.92 30.39,97.98 39.00,99.38 49.38,101.05 50.99,90.53 51.00,83.00 51.00,83.00 51.00,79.00 51.00,79.00 51.03,65.87 54.85,70.20 55.00,58.00 55.13,46.70 58.24,27.94 42.00,27.04 39.14,26.89 37.76,27.08 35.00,27.70 Z",
    "M 39.00,2.62 C 26.15,6.31 29.19,20.79 39.00,22.38 52.56,24.57 55.81,1.99 39.00,2.62 Z M 35.00,27.70 C 23.65,32.93 26.00,42.57 26.00,53.00 26.00,56.66 25.76,63.74 26.53,67.00 27.94,72.98 29.86,72.21 30.00,80.00 30.14,88.38 27.53,103.52 39.00,105.38 49.38,107.05 50.99,96.53 51.00,89.00 51.00,89.00 51.00,82.00 51.00,82.00 51.03,68.87 54.85,73.20 55.00,61.00 55.00,61.00 55.00,44.00 55.00,44.00 54.99,35.01 52.44,27.62 42.00,27.04 39.14,26.89 37.76,27.08 35.00,27.70 Z",
    "M 39.00,2.62 C 26.15,6.31 29.19,20.79 39.00,22.38 52.56,24.57 55.81,1.99 39.00,2.62 Z M 35.00,27.70 C 23.24,33.12 26.00,44.22 26.00,55.00 26.00,58.79 25.75,66.68 26.53,70.00 27.94,75.98 29.86,75.21 30.00,83.00 30.14,91.68 27.19,108.47 39.00,110.38 49.38,112.05 50.99,101.53 51.00,94.00 51.00,94.00 51.00,85.00 51.00,85.00 51.03,71.58 54.93,76.70 55.00,63.00 55.00,63.00 55.00,49.00 55.00,49.00 55.00,39.02 55.55,27.79 42.00,27.04 39.14,26.89 37.76,27.08 35.00,27.70 Z",
    "M 39.00,2.62 C 26.15,6.31 29.19,20.79 39.00,22.38 52.56,24.57 55.81,1.99 39.00,2.62 Z M 35.00,27.70 C 22.83,33.31 26.00,46.85 26.00,58.00 26.00,61.79 25.75,69.68 26.53,73.00 27.94,78.98 29.86,78.21 30.00,86.00 30.15,95.09 26.78,114.40 39.00,116.38 49.38,118.05 50.99,107.53 51.00,100.00 51.00,100.00 51.00,88.00 51.00,88.00 51.03,74.58 54.93,79.70 55.00,66.00 55.00,66.00 55.00,50.00 55.00,50.00 55.00,39.82 55.95,27.81 42.00,27.04 39.14,26.89 37.76,27.08 35.00,27.70 Z",
    "M 39.00,2.62 C 26.15,6.31 29.19,20.79 39.00,22.38 52.56,24.57 55.81,1.99 39.00,2.62 Z M 35.00,27.70 C 22.83,33.31 26.00,46.85 26.00,58.00 26.00,61.79 25.75,69.68 26.53,73.00 27.94,78.98 29.86,78.21 30.00,86.00 30.15,95.09 26.78,114.40 39.00,116.38 49.38,118.05 50.99,107.53 51.00,100.00 51.00,100.00 51.00,88.00 51.00,88.00 51.03,74.58 54.93,79.70 55.00,66.00 55.00,66.00 55.00,50.00 55.00,50.00 55.00,39.82 55.95,27.81 42.00,27.04 39.14,26.89 37.76,27.08 35.00,27.70 Z"
];

export const POPULATION_COLOR = '#3291C8'

export const populationStyle = (feature) =>
{
    let count = feature.get("count")
    let codeLength = feature.get("code").length
    let unitMAX = {
        7: 10_000, // bag horoo
        5: 20_000, // sum duureg
        3: 50_000, // aimag horoo

    }
    let label = feature.get("location")
    count = parseInt(count)
    count = isNaN(count) ? 1 : count

    const MAX = unitMAX[codeLength]
    const percent = count / MAX * 100

    let symbolSize = Math.min(Math.floor(percent / 10), POPULATION_SVG.length - 1)
    var symbolColor = POPULATION_COLOR
    var svgPathIndex = symbolSize

    var svgWidth;
    var circleX;
    var rectWidth;
    var textX;

    var adminNm = quantityFormat(count)

    svgWidth = "80px";
    circleX = "67";
    rectWidth = "55";
    textX = 0
    var svgImg = '<svg xmlns="http://www.w3.org/2000/svg" ' +
    'width="'+svgWidth+'" height="'+(115+svgPathIndex*10) + 'px"><defs><filter id="shadow"><feDropShadow dx="3" dy="3"/></filter></defs><g style="filter:url(#shadow);">' +
    '<path opacity="0.65" style="fill:'+symbolColor+'" ' +
    ' d="'+ POPULATION_SVG[svgPathIndex] + '" /></g> ' +
    '<circle cx="17" cy="'+((svgPathIndex*5)+87)+'" r="13.6" style="fill:#303030"/> ' +
    '<circle cx="'+circleX+'" cy="'+((svgPathIndex*5)+87)+'" r="13.6" style="fill:#303030"/> ' +
    '<rect x="16" y="'+((svgPathIndex*5)+73.5)+'" width="'+rectWidth+'" height="27" style="fill:#303030"/> ' +
    '<text x="11" y="'+((svgPathIndex*5)+84)+'" transform="translate(40)" font-size="9" align="center" style="fill:rgb(255,255,255)"> ' +
    '<tspan x="'+textX+'" text-anchor="middle">' + adminNm + '</tspan></text> ' +
    '<text x="11" y="'+((svgPathIndex*5)+96)+'" transform="translate(40)" font-size="9" align="center" style="fill:rgb(255,255,255)"> ' +
    '<tspan x="'+textX+'" text-anchor="middle">' + label + '</tspan></text> ' +
    '</svg>';

    const newStyle = new Style({
        image: new Icon({
            src: 'data:image/svg+xml;utf8,' + encodeURIComponent(svgImg)
        })
    })

    return newStyle
}

export const lefStyleStyle = (feature) =>
{
    let count = feature.get("count")
    let codeLength = feature.get("code").length
    let unitMAX = {
        6: 10_000, // bag horoo
        4: 20_000, // sum duureg
        2: 50_000, // aimag horoo

    }
    let label = feature.get("location")
    count = parseInt(count)
    count = isNaN(count) ? 1 : count

    const MAX = unitMAX[codeLength]
    const percent = count / MAX * 100

    let symbolSize = Math.min(Math.floor(percent / 10), POPULATION_SVG.length - 1)
    var symbolColor = POPULATION_COLOR
    var svgPathIndex = symbolSize

    var svgWidth;
    var circleX;
    var rectWidth;
    var textX;

    var adminNm = quantityFormat(count)

    svgWidth = "80px";
    circleX = "67";
    rectWidth = "55";
    textX = 0
    var svgImg = '<svg xmlns="http://www.w3.org/2000/svg" ' +
    'width="'+svgWidth+'" height="'+(115+svgPathIndex*10) + 'px"><defs><filter id="shadow"><feDropShadow dx="3" dy="3"/></filter></defs><g style="filter:url(#shadow);">' +
    '<path opacity="0.65" style="fill:'+symbolColor+'" ' +
    ' d="'+ POPULATION_SVG[svgPathIndex] + '" /></g> ' +
    '<circle cx="17" cy="'+((svgPathIndex*5)+87)+'" r="13.6" style="fill:#303030"/> ' +
    '<circle cx="'+circleX+'" cy="'+((svgPathIndex*5)+87)+'" r="13.6" style="fill:#303030"/> ' +
    '<rect x="16" y="'+((svgPathIndex*5)+73.5)+'" width="'+rectWidth+'" height="27" style="fill:#303030"/> ' +
    '<text x="11" y="'+((svgPathIndex*5)+84)+'" transform="translate(40)" font-size="9" align="center" style="fill:rgb(255,255,255)"> ' +
    '<tspan x="'+textX+'" text-anchor="middle">' + adminNm + '</tspan></text> ' +
    '<text x="11" y="'+((svgPathIndex*5)+96)+'" transform="translate(40)" font-size="9" align="center" style="fill:rgb(255,255,255)"> ' +
    '<tspan x="'+textX+'" text-anchor="middle">' + label + '</tspan></text> ' +
    '</svg>';

    const newStyle = new Style({
        image: new Icon({
            src: 'data:image/svg+xml;utf8,' + encodeURIComponent(svgImg)
        })
    })

    return newStyle
}

export const styleOfRadiusPoint = (feature, resolution) =>
{
    const style = new Style(
        {
            image: new CircleStyle(
                {
                    radius: 10,
                    stroke: new Stroke(
                        {
                            color: '#fff',
                        }
                    ),
                    fill: new Fill(
                        {
                            color: '#3399CC',
                        }
                    ),
                }
            ),
            text: new Text(
                {
                    text: feature.get("adistance"),
                    font: '12px Calibri,sans-serif',
                    fill: new Fill({ color: '#000' }),
                    stroke: new Stroke(
                        {
                            color: '#fff',
                            width: 2
                        }
                    )
                }
            ),
        }
    )
    return style
}

export const LIFESTYLE_ORG_IMAGE = {
    "1": `data:image/svg+xml;utf8,${encodeURIComponent(`<svg style='fill: #0191c8; background-color: white' xmlns="http://www.w3.org/2000/svg" viewBox="-2.5 -2.5 30 28" width="512" height="512"><path d="M16,24H8a3,3,0,0,1-2.886-2.225,6.993,6.993,0,0,1,13.772,0A3,3,0,0,1,16,24ZM3,21V13.424A5,5,0,0,0,0,18v1a5,5,0,0,0,3.924,4.876A4.953,4.953,0,0,1,3,21Zm18-7.576V21a4.953,4.953,0,0,1-.924,2.876A5,5,0,0,0,24,19V18A5,5,0,0,0,21,13.424ZM19,11v6.356a8.978,8.978,0,0,0-14,0V11A6.992,6.992,0,0,1,8,5.264V4a4,4,0,0,1,8,0V5.264A6.992,6.992,0,0,1,19,11ZM10,4.3a6.927,6.927,0,0,1,4,0V4a2,2,0,0,0-4,0ZM15,10h0a1,1,0,0,0-1-1H10a1,1,0,0,0-1,1H9a1,1,0,0,0,1,1h4A1,1,0,0,0,15,10Z"/></svg>`)}`,
    "2": `data:image/svg+xml;utf8,${encodeURIComponent(`<svg style='fill: #0191c8; background-color: white' xmlns="http://www.w3.org/2000/svg" height="512" viewBox="-3 -3 30 30" width="512"><path d="m19 5h-3v-1a3 3 0 0 0 -3-3h-2a3 3 0 0 0 -3 3v1h-3a5.006 5.006 0 0 0 -5 5v8a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5v-8a5.006 5.006 0 0 0 -5-5zm-9-1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4zm5 11h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 0 2z"/></svg>`)}`,
    "3": `data:image/svg+xml;utf8,${encodeURIComponent(`<svg style='fill: #0191c8; background: white' xmlns="http://www.w3.org/2000/svg" height="512" viewBox="-2 -2 28 28" width="512"><path d="m16 24h-8v-8h-8v-8h8v-8h8v8h8v8h-8z"/></svg>`)}`,
    "4": `data:image/svg+xml;utf8,${encodeURIComponent(`<svg style='fill: #0191c8; background-color: white' xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 27 27" width="512" height="512"><path d="M24,11.5a3.5,3.5,0,0,0-2.149-3.226,10,10,0,0,0-19.7,0,3.5,3.5,0,0,0,1.119,6.718,10.607,10.607,0,0,0,2.071,2.955,8.908,8.908,0,0,0-2.272,4.928A1,1,0,0,0,4.06,24H19.919a1,1,0,0,0,.992-1.124,8.9,8.9,0,0,0-2.261-4.918,10.622,10.622,0,0,0,2.082-2.966A3.5,3.5,0,0,0,24,11.5Zm-3.752,1.473a.993.993,0,0,0-1.117.651C18.215,16.222,15.13,19,12,19s-6.215-2.78-7.131-5.378a.994.994,0,0,0-1.117-.651A1.606,1.606,0,0,1,3.5,13a1.5,1.5,0,0,1-.27-2.972,1,1,0,0,0,.816-.878A7.966,7.966,0,0,1,8.711,2.71a3.534,3.534,0,1,0,6.578,0,7.966,7.966,0,0,1,4.665,6.44,1,1,0,0,0,.816.878A1.5,1.5,0,0,1,20.5,13,1.606,1.606,0,0,1,20.248,12.973Z"/><circle cx="9.5" cy="11.5" r="1.5"/><circle cx="14.5" cy="11.5" r="1.5"/></svg>`)}`
}

export const lifeStyleStyle = (checkedTypes, feature, resolution) =>
{

    const svgWithType = (type, count, name) =>
    {
        const perHeight = 50
        let images = {
            "1": [`
                <image
                    x="60px"
                    y="30px"
                    overflow="visible"
                    transform="matrix(0.3082 0 0 0.3082 0.000488281 0)"
                    width="130"
                    height="60"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="${LIFESTYLE_ORG_IMAGE["1"]}">
                </image>`,
            10, 43],
            "2": [
                `
                <image
                    x="60px"
                    y="24px"
                    overflow="visible"
                    transform="matrix(0.3077 0 0 0.3077 4.882813e-004 0.0718)"
                    width="130"
                    height="70"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="${LIFESTYLE_ORG_IMAGE["2"]}">
                </image>
                `, 10, 43
            ],
            "3": [`
                <image
                    x="61"
                    y="30px"
                    overflow="visible"
                    transform="matrix(0.3082 0 0 0.3082 0.000488281 0)"
                    width="120"
                    height="60"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="${LIFESTYLE_ORG_IMAGE["3"]}">
                </image>
            `, 10, 43],
            "4": [
                `<image
                    x="61"
                    y="30px"
                    overflow="visible"
                    transform="matrix(0.3082 0 0 0.3082 0.000488281 0)"
                    width="125"
                    height="60"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="${LIFESTYLE_ORG_IMAGE["4"]}">
                </image>
                `, 10, 43
            ]
        }
        let imageConfig = images[type + ""]
        const MAX_HEIGHT = imageConfig[2]
        let code = feature.get("code")
        let ISUB = code.startsWith("11")
        let codeLength = code.length
        let unitMAX = {
            "1": {
                6: ISUB ? 50 : 1, // bag horoo
                4: ISUB ? 100 : 5, // sum duureg
                2: ISUB ? 500 : 20, // aimag horoo
            },
            "2": {
                6: ISUB ? 10 : 1, // bag horoo
                4: ISUB ? 40 : 5, // sum duureg
                2: ISUB ? 100 : 20, // aimag horoo
            },
            "3": {
                6: ISUB ? 20 : 1, // bag horoo
                4: ISUB ? 150 : 5, // sum duureg
                2: ISUB ? 1000 : 20, // aimag horoo
            },
            "4": {
                6: ISUB ? 50 : 1, // bag horoo
                4: ISUB ? 100 : 5, // sum duureg
                2: ISUB ? 1000 : 20, // aimag horoo
            }
        }
        const MAX_COUNT = unitMAX[type][codeLength]
        let percent = Math.min(parseFloat(count) / MAX_COUNT * 100, 100)
        let height = Math.max(percent / 100 * MAX_HEIGHT, 10 / 100 * MAX_HEIGHT)

        return (`
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="80px"
                height="${perHeight}"
                xmlns:xml="http://www.w3.org/XML/1998/namespace"
                xml:space="preserve"
                version="1.1"
            >
                ${imageConfig[0]}
                <rect
                    x="60"
                    y="${imageConfig[1]}"
                    width="20"
                    height="${height}"
                    style="fill:#324678"
                    transform="rotate(90 150 160) rotate(90 -60 110)"
                />
                <rect x="20" y="29" width="38" height="21" style="fill:#303030;"/>
                <text x="8" y="43" transform="translate(38)" font-size="11px" align="center" style="fill:rgb(255,255,255)">
                <tspan x="0" text-anchor="middle">${count}</tspan></text>
            </svg>
        `)
    }

    let location = feature.get("location")
    const styles = []
    // styles.push(
    //     new Style({
    //         image: new Icon({
    //             src: 'data:image/svg+xml;utf8,' + encodeURIComponent(
    //                 `
    //                 <svg
    //                     xmlns="http://www.w3.org/2000/svg"
    //                     x="0"
    //                     y="0px"
    //                     height="${100 * checkedTypes.length}"
    //                     width="auto"
    //                     xmlns:xml="http://www.w3.org/XML/1998/namespace"
    //                     xml:space="preserve"
    //                     version="1.1"
    //                 >
    //                     <rect
    //                         x="0"
    //                         y="0"
    //                         width="110"
    //                         height="${100 * checkedTypes.length}"
    //                         transform="rotate(90 150 160) rotate(90 -60 110)"
    //                         style="fill:#303030"
    //                     />
    //                 </svg>
    //                 `
    //             ),
    //             anchor: [0.25, 0],
    //             anchorXUnits: 'fraction',
    //             anchorYUnits: 'fraction',
    //         }),
    //     })
    // )
    styles.push(
        new Style({
            image: new Icon({
                src: 'data:image/svg+xml;utf8,' + encodeURIComponent(
                    `
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0px"
                        width="${location.length * 11}"
                        height="20"
                        xmlns:xml="http://www.w3.org/XML/1998/namespace"
                        xml:space="preserve"
                        version="1.1"
                    >
                        <defs>
                            <filter x="0" y="0" width="1" height="1" id="solid">
                            <feFlood flood-color="#303030" result="bg" />
                            <feMerge>
                                <feMergeNode in="bg"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                            </filter>
                        </defs>
                        <text filter="url(#solid)" x="0" y="11" font-size="11px" style="fill:rgb(255,255,255)">
                            ${location}
                        </text>
                    </svg>
                    `
                ),
                anchor: [0.2, 0],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
            }),
        })
    )
    const NAME = 0
    const COUNT = 1
    let startWidth = checkedTypes.length > 1
                    ?
                        [
                            [70, 50],      [5, 50],
                            [70, 45*2+5],  [5, 45*2+5]
                        ]
                    :
                        [
                            [50, 50]
                        ]
    checkedTypes
        .filter((type) => feature.get(type))
        .map(
            (type, idx) =>
            {
                let featureProperty = feature.get(type)
                const properties = featureProperty.split("|")
                const name = properties[NAME]
                const count = properties[COUNT]
                styles.push(
                    new Style({
                        image: new Icon({
                            src: 'data:image/svg+xml;utf8,' + encodeURIComponent(svgWithType(type, count, location)),
                            anchor: [startWidth[idx][0], startWidth[idx][1]], // 65 гэдэг нь marker ийн нийт өндрөөс 10 ийг хасаад гарч байгаа тоо
                            anchorXUnits: 'pixels',
                            anchorYUnits: 'pixels',
                        }),
                    })
                )
            }
        )

    return styles
}

export const styleOfPrice = (tabName, feature) =>
{
    const countKey = 'building_count'
    const labelKey = "unit_name"
    let count = feature.get(countKey)

    let unitKey = "code"
    let unitMAX = {
        6: 100, // bag horoo
        4: 1000, // sum duureg
        2: 10000, // aimag horoo
    }
    let label = feature.get(labelKey)
    count = parseInt(count)
    count = isNaN(count) ? 1 : count

    const MAX = unitMAX[feature.get(unitKey).length]
    const percent = count / MAX * 100

    let colors = {
        "first": POPULATION_COLOR,
        "second": "#324678"
    }

    let MAX_HEIGHT = 25
    let symbolSize = Math.max((percent / 100 * MAX_HEIGHT), 0)
    let symbolColor = colors[tabName] ?? POPULATION_COLOR

    let svgWidth;
    let adminNm = quantityFormat(count)

    svgWidth = "85px";

    let svgImg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="${svgWidth}" height="${115 + symbolSize}">
        <defs><filter id="shadow"><feDropShadow dx="3" dy="3" stdDeviation="3"/></filter></defs>
        <g style="filter:url(#shadow);">
        <path style="fill:${symbolColor}"  d="M 40.00,1.00
            C 40.00,1.00 17.44,23.69 17.44,23.69
                17.44,23.69 21.00,27.00 21.00,27.00
                28.00,20.00 33.00,14.88 40.13,7.75
                40.13,7.75 59.69,27.31 59.69,27.31
                59.69,27.31 63.00,24.00 63.00,24.00
                58.75,19.88 40.00,1.13 40.00,1.00 Z
            M 40.00,13.00
            C 40.00,13.00 25.00,28.13 25.00,28.13
                25.00,28.13 25.00,54.00 25.00,54.00
                25.00,54.00 56.00,54.00 56.00,54.00
                56.00,49.64 55.91,40.55 55.88,28.25
                52.18,24.82 44.43,17.22 40.00,13.00 Z" />
        <rect x="25" y="54" width="31" height="${symbolSize + 7}" style="fill: ${symbolColor}" />
        </g>
        <circle cx="17" cy="${77 + symbolSize}" r="13.6" style="fill:#303030"/>
        <circle cx="70" cy="${77 + symbolSize}" r="13.6" style="fill:#303030"/>
        <rect x="16" y="${symbolSize + 63.5}" width="55" height="27" style="fill:#303030"/>
        <text x="11" y="${symbolSize + 74}" transform="translate(40)" font-size="9" align="center" style="fill:rgb(255,255,255)">
        <tspan x="0" text-anchor="middle">${adminNm}</tspan></text>
        <text x="11" y="${symbolSize + 86}" transform="translate(40)" font-size="9" align="center" style="fill:rgb(255,255,255)">
        <tspan x="0" text-anchor="middle">${label}</tspan></text>
        </svg>
    `;

    const newStyle = new Style({
        image: new Icon({
            src: 'data:image/svg+xml;utf8,' + encodeURIComponent(svgImg)
        }),
        zIndex: 2,
    })

    return newStyle
}
