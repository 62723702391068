import Pages from "./pages";
import { BrowserRouter } from "react-router-dom";

import ModalContextProvider from "context/modalContext";
import HeaderContextProvider from "context/special/headerContext";
import AuthContextProvider from "context/authContext";
import MapContextProvider from "context/special/mapContext";
import StateContextProvider from 'context/stateContext'
import FrameContextProvider from 'context/frameContext';
import ConfigContextProvider from "context/configContext";
import { SocketContextProvider } from "context/socketContext";
function App() {
    return (
        <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
            <ConfigContextProvider>
                <FrameContextProvider>
                    <HeaderContextProvider>
                        <AuthContextProvider>
                            <SocketContextProvider>
                                <ModalContextProvider>
                                    <MapContextProvider>
                                        <StateContextProvider>
                                            <Pages />
                                        </StateContextProvider>
                                    </MapContextProvider>
                                </ModalContextProvider>
                            </SocketContextProvider>
                        </AuthContextProvider>
                    </HeaderContextProvider>
                </FrameContextProvider>
            </ConfigContextProvider>
        </BrowserRouter>
    );
}

export default App;
