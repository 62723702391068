import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

import useMap from "hooks/map/useMap";
import useMapContext from "hooks/useMapContext";
import useToast from "hooks/useToast";

import MapControls from "components/special/MapControls";
import Scale from "components/special/MapControls/Scale";
import COverlay from "components/special/MapControls/Overlay";
import SearchField from "components/special/SearchField";
import OverlayBuilding from "./OverLayBuilding";

import "ol/ol.css";
import {
    styleClusterPoint,
    selectClusterFeatureStyle,
    styleOfAu,
    styleOfAuHover,
    styleOfCadaster,
} from "hooks/map/helper";
import { CLUSTER_DISTANCE } from "hooks/map/CMap";
import BuildingPriceAnalytacs from "./BuildingPriceAnalytacs";
import FilterAnalyze from "./FilterAnalyze";
import { StateContext } from "context/stateContext";
import css from "../style.module.css";

const LAYER_NAME = "building";

export default function MapApp({ menu }) {
    const [dataTypeHudaldaa, setDataTypeHudaldaa] = useState(true);
    const [showLegend, setShowLegend] = useState(true);
    const [toggleBaseLayer, setToggleBaseLayer] = useState(false);
    const [layers, setLayers] = useState([]);
    const [color, setColors] = useState(true);

    const {
        buildings,
        isFly,
        setCMap,
        auLayer,
        auSource,
        cMapState,
        setSelectedFeature,
        allFilters,
    } = useMapContext();
    const { setSelectedFeature: fromContextSetFeature } =
        useContext(StateContext);
    const { addToast } = useToast();

    const { map, cMap } = useMap({});

    /** feature дээр select дарагдах үед overlay ийг харуулах*/
    const handleClickFeature = (event, clusterSource) => {
        const selectedFeature = event.selected[0];

        /** cluster аас өөр feature байх юм бол дарахгүй байх */
        const isCluster = cMap.isCluster(selectedFeature);
        if (!isCluster) return;

        if (!selectedFeature) return;

        const selectedCoordinate = event.mapBrowserEvent.coordinate;

        /** хэрвээ нэг feature ээс олон байх юм бол zoom татаж ойртох */
        const features = selectedFeature.get("features");
        const zoomLvl = map.getView().getZoom();
        if (features.length > 1 && zoomLvl < 19) {
            /** map zoom level тулаад байгаа хэрнээ cluster харагдахгүй байвал cluster distance ийг багасгах */
            // if (map.getView().getZoom() > 19)
            // {
            //     clusterSource.setDistance(5);
            // }
            // else
            // {
            //     clusterSource.setDistance(parseInt(CLUSTER_DISTANCE, 10));
            //     clusterSource.setMinDistance(parseInt(CLUSTER_DISTANCE / 2, 10));
            // }

            const extent = cMap.getBoundingExtent(
                features.map((feature) =>
                    feature.getGeometry().getCoordinates()
                )
            );
            cMap.fitToExtent(extent, {
                duration: 2000,
                padding: [250, 250, 250, 250],
            });
            return;
        }

        cMap.displayOverlay(selectedCoordinate);
        const convertedData = features.map((feature) => {
            const apartment = feature.get("apartment");
            const aparmentId =
                typeof apartment === "object" ? apartment.id : apartment;
            return {
                id: feature.get("id"),
                apartmentId: aparmentId,
            };
        });
        setSelectedFeature({ feature: convertedData, state: "clicked" });
    };

    const setBuildingsToMap = (data) => {
        const layerName = `${LAYER_NAME}`;
        cMap.removeLayer(layerName);

        if (data.length === 0) {
            addToast({
                text: "Хайлтын үр дүн хоосон байна",
                time: 3000,
                type: "warning",
            });
            return;
        }

        /** back аас ирсэн датанаас feature үүдийг үүсгэж авах нь */
        const features = data.map((element, idx) => {
            const feature = cMap.parseGeoJsonToFeature(
                element.point_geojson
            )[0];
            feature.setProperties(element);
            return feature;
        });
        const vSource = cMap.createVectorSource(features);
        const clusterSource = cMap.createCluster(vSource);
        const { vectorLayer, vectorSource } = cMap.addVectorLayer(
            features,
            clusterSource,
            layerName,
            styleClusterPoint
        );
        cMap.createSelect(
            "click",
            (event) => handleClickFeature(event, clusterSource),
            selectClusterFeatureStyle,
            [vectorLayer],
            [],
            "building"
        );

        /** feature үүд рүү нисэж очих эсэх */
        if (isFly) {
            /** cluster feature үүдийн extent */
            const extent = clusterSource.getSource().getExtent();
            cMap.fitToExtent(extent, {
                padding: [700, 600, 700, 600],
                duration: 2000,
                maxZoom: 18,
            });
        }
    };

    /** засаг захиргааны нэгж дээр дарах үед */
    const handleClickAu = (feature, clickedCoordinate) => {
        fromContextSetFeature(feature);
        cMap.displayOverlay(clickedCoordinate);
        setSelectedFeature({
            feature: {},
            state: "pie",
            codes: feature.get("localid"),
        });
    };

    /** Газрын зурган дээр хил заагийг WFS ээр харуулах */
    const setAuWFS = () => {
        const wfsUrl = "https://geo.nsdi.gov.mn/geoserver/gp_bnd/wfs";
        const cadasterWfsUrl = "https://geo.nsdi.gov.mn/geoserver/gp_cp/wfs";

        const typeNameCadaster = "gp_cp:gp_layer_cadastral_parcel_cp_view";
        const typeNameBagHoroo =
            "gp_bnd:gp_layer_c_4thorder_administrativeunit_au_view";
        const typeNameSumDuureg =
            "gp_bnd:gp_layer_c_3rdorder_administrativeunit_au_view";
        const typeNameAimagNiislel =
            "gp_bnd:gp_layer_c_2ndorder_administrativeunit_au_view";
        const typeNameUls =
            "gp_bnd:gp_layer_c_1storder_administrativeunit_au_view";

        const vectorSourceCadaster = cMap.wfsFromGeoserver(
            cadasterWfsUrl,
            typeNameCadaster
        );
        const vectorSourceBagHoroo = cMap.wfsFromGeoserver(
            wfsUrl,
            typeNameBagHoroo
        );
        const vectorSourceSumDuureg = cMap.wfsFromGeoserver(
            wfsUrl,
            typeNameSumDuureg
        );
        const vectorSourceAimagNiislel = cMap.wfsFromGeoserver(
            wfsUrl,
            typeNameAimagNiislel
        );
        const vectorSourceUls = cMap.wfsFromGeoserver(wfsUrl, typeNameUls);

        const {
            vectorLayer: vectorLayerCadaster,
            vectorSource: vectorSourcegCadaster,
        } = cMap.addVectorLayer(
            [],
            vectorSourceCadaster,
            "gs_au_5",
            styleOfCadaster,
            { minZoom: 16, maxZoom: 22 }
        );
        const {
            vectorLayer: vectorLayerBagHoroo,
            vectorSource: vectorSourcegUnit1,
        } = cMap.addVectorLayer(
            [],
            vectorSourceBagHoroo,
            "gs_au_4",
            styleOfAu,
            { minZoom: 14.5, maxZoom: 22 }
        );
        const {
            vectorLayer: vectorLayerSumDuureg,
            vectorSource: vectorSourcegUnit2,
        } = cMap.addVectorLayer(
            [],
            vectorSourceSumDuureg,
            "gs_au_3",
            styleOfAu,
            { minZoom: 10, maxZoom: 14.5 }
        );
        const {
            vectorLayer: vectorLayerAimagNiislel,
            vectorSource: vectorSourcegUnit3,
        } = cMap.addVectorLayer(
            [],
            vectorSourceAimagNiislel,
            "gs_au_2",
            styleOfAu,
            { minZoom: 5, maxZoom: 10 }
        );
        const { vectorLayer: vectorLayerUls } = cMap.addVectorLayer(
            [],
            vectorSourceUls,
            "gs_au_1",
            styleOfAu,
            { minZoom: 1, maxZoom: 5 }
        );

        layers.push(vectorLayerCadaster);
        layers.push(vectorLayerBagHoroo);
        layers.push(vectorLayerSumDuureg);
        layers.push(vectorLayerAimagNiislel);
        layers.push(vectorLayerUls);

        let selected = null;

        /** засаг захиргааны нэгжийн layer эсэхийг шалгах нь */
        function checkLayerName(layer) {
            const layerName = layer.get("name");
            if (layerName && layerName.startsWith("gs_au")) return true;
            return false;
        }

        function pointer(e) {
            if (e.dragging) {
                return;
            }

            if (selected !== null) {
                selected.setStyle(undefined);
                selected = null;
            }

            const pixel = map.getEventPixel(e.originalEvent);
            map.forEachFeatureAtPixel(pixel, function (f, layer) {
                /** барилгын feature байвал style солихгүй */
                if (!checkLayerName(layer)) return false;

                selected = f;
                f.setStyle(styleOfAuHover(selected));
                return true;
            });

            if (selected) {
                /** тухайн засаг захиргааны нэгжийн нэрийг харуулах нь */
                const properties = selected.getProperties();
                const aimag = properties.administrativeunitmajorclass;
                const sum = properties.administrativeunitsubclass;
                const horoo = properties.administrativeunitprimaryclass;
                const text = properties.text;
                const auTitleElement = document.getElementById("au-title");
                auTitleElement.innerText = !aimag
                    ? text
                    : `${aimag}${sum ? ", " + sum : ""}${
                          horoo ? ", " + horoo : ""
                      }`;
            }
        }

        map.un("pointermove", pointer);
        map.on("pointermove", pointer);
    };

    useEffect(() => {
        if (map) {
            setCMap(cMap);
            setAuWFS();
        }
    }, [map]);

    // [[1, 1.5], "rgb(102,214,255)", 5 / 10]
    // 	[[1.5, 2], "rgb(56,153,255)", 6 / 10]
    // 	[[2, 3], "rgb(22,179,52)", 7 / 10]
    // 	[[3, 5], "rgb(246,142,22)", 8 / 10]
    // 	[[5, 8], "rgb(244,78,20)", 9 / 10]
    // 	[[8, 100000000000000000000], "rgb(211,33,44)", 10 / 10]

    /**
     * Legend ийн датаг харуулах нь feature ээс авч
     * @param {*} features
     */
    const displayLegendDatas = useMemo(() => {
        return (
            <Fragment>
                <div className="d-flex align-items-center mb-1 mt-1">
                    <div
                        style={{
                            backgroundColor: "rgb(102,214,255)",
                            height: "10px",
                            width: "10px",
                            marginRight: "10px",
                        }}
                    ></div>
                    <div>1 - 1.5 сая</div>
                </div>
                <div className="d-flex align-items-center mb-1 mt-1">
                    <div
                        style={{
                            backgroundColor: "rgb(56,153,255)",
                            height: "10px",
                            width: "10px",
                            marginRight: "10px",
                        }}
                    ></div>
                    <div>1.5 - 2 сая</div>
                </div>
                <div className="d-flex align-items-center mb-1 mt-1">
                    <div
                        style={{
                            backgroundColor: "rgb(22,179,52)",
                            height: "10px",
                            width: "10px",
                            marginRight: "10px",
                        }}
                    ></div>
                    <div>2 - 3 сая</div>
                </div>
                <div className="d-flex align-items-center mb-1 mt-1">
                    <div
                        style={{
                            backgroundColor: "rgb(246,142,22)",
                            height: "10px",
                            width: "10px",
                            marginRight: "10px",
                        }}
                    ></div>
                    <div>3 - 5 сая</div>
                </div>
                <div className="d-flex align-items-center mb-1 mt-1">
                    <div
                        style={{
                            backgroundColor: "rgb(244,78,20)",
                            height: "10px",
                            width: "10px",
                            marginRight: "10px",
                        }}
                    ></div>
                    <div>5 - 8 сая</div>
                </div>
                <div className="d-flex align-items-center mb-1 mt-1">
                    <div
                        style={{
                            backgroundColor: "rgb(211,33,44)",
                            height: "10px",
                            width: "10px",
                            marginRight: "10px",
                        }}
                    ></div>
                    <div>8 саяас дээш</div>
                </div>
            </Fragment>
        );
    }, []);

    // useEffect(
    //     () =>
    //     {
    //         if (map)
    //         {
    //             const actionType = dataTypeHudaldaa ? "hudaldaa" : "turees"
    //             const visibleName = actionType === "hudaldaa" ? "turees" : "hudaldaa"
    //             let foundLayer = visibleLayer(visibleName, false)
    //             let newLayer = visibleLayer(actionType, true)
    //             if (!newLayer)
    //             {
    //                 const kmlUrl = `${process.env.REACT_APP_SERVER_URL}/media/kml/${actionType}.kml?time=${Date.now()}`
    //                 cMap.createClusterWithUrl(kmlUrl, actionType)
    //             }
    //         }
    //     },
    //     [map, dataTypeHudaldaa]
    // )

    const visibleLayer = (layerName, visible) => {
        let foundLayer = null;
        map.getLayers()
            .getArray()
            .map((layer, idx) => {
                if (layer.get("name") === layerName) {
                    layer.setVisible(visible);
                    foundLayer = layer;
                }
            });
        return foundLayer;
    };

    useEffect(() => {
        if (map) {
            const actionType = dataTypeHudaldaa ? "hudaldaa" : "turees";
            visibleLayer(actionType, color);
        }
    }, [map, color]);

    useEffect(() => {
        if (map && buildings && buildings.data) {
            setBuildingsToMap(buildings.data);
        }
    }, [map, buildings]);

    /** overlay хаагдах үед */
    const handleClose = () => {
        // setSelectedFeature([])
    };

    // useEffect(
    //     () =>
    //     {
    //         if(!auSource) return
    //         if(!cMapState) return
    //         if(!cMap) return
    //         cMap.displayOverlay(cMapState?.getCenterFromExtent(auSource?.getExtent()))
    //         setSelectedFeature({ feature: {}, state: "pie" })
    //     },
    //     [cMapState, auSource]
    // )

    const overlay = useMemo(() => {
        return (
            <COverlay
                map={map}
                cMap={cMap}
                component={<OverlayBuilding auSource={auSource} cMap={cMap} />}
                onClose={handleClose}
                margin={250}
            />
        );
    }, [map, auSource]);

    return (
        <div
            id="map"
            style={{
                width: "100%",
                height: "100vh",
                position: "relative",
                overflow: "hidden",
            }}
        >
            <BuildingPriceAnalytacs />
            <FilterAnalyze />
            {/* <SearchField /> */}
            <MapControls
                showButtons={["fs", "layers"]}
                extraBtns={
                    [
                        // {
                        //     icon: !color ? "fas fa-eye" : "fas fa-eye-slash",
                        //     onClick: () => setColors(!color),
                        //     title: !color ? "Өнгө харуулах" : "Өнгө нуух",
                        // },
                        // {
                        // 	icon: dataTypeHudaldaa ? "fas fa-text" : "fas fa-money-check-alt",
                        // 	onClick: () => color && setDataTypeHudaldaa(!dataTypeHudaldaa),
                        // 	title: dataTypeHudaldaa ? "Түрээслэх" : "Худалдах",
                        //     disabled: !color
                        // },
                        // {
                        // 	icon: showLegend ? "fas fa-list" : "fas fa-list",
                        // 	onClick: () => color && setShowLegend(!showLegend),
                        // 	title: showLegend ? "Таних тэмдэг нуух" : "Таних тэмдэг харах",
                        //     disabled: !color
                        // },
                        // {
                        //     icon: 'fas fa-layer-group',
                        //     onClick: () => setToggleBaseLayer(!toggleBaseLayer),
                        //     title: 'Суурь давхарга солих',
                        // },
                    ]
                }
                map={map}
                cMap={cMap}
                onClickLayers={() => setToggleBaseLayer(!toggleBaseLayer)}
            />
            <Scale map={map} />
            {menu}
            {overlay}
            {/* <div className={`${css.legendContainer}`}>
                <div className="d-flex">
                    <div className={css.closeBtn} onClick={() => setShowLegend(false)}>
                        <i className="fas fa-arrow-alt-to-right"></i>
                    </div>
                    <div className={css.legendTitle}>Таних тэмдэг(₮)</div>
                </div>
                <div className={css.legendWrapper}>
                    {displayLegendDatas}
                </div>
			</div> */}
            <div className="au-title-container">
                <div className="au-title" id="au-title"></div>
            </div>
        </div>
    );
}
