import { objectToQueryString } from "utils/browser"

const AUTH_URI = "https://sso.gov.mn/oauth2/authorize"
// const CALLBACK_URI = "https://oronsuuts.mcud.gov.mn/sign/oauth2/"
const CALLBACK_URI = "https://oronsuuts.mcud.gov.mn/Dan/DanRedirect"
const TOKEN_URI = "https://sso.gov.mn/oauth2/token"
const SERVICE = "https://sso.gov.mn/oauth2/api/v1/service"

export default class DanAuth
{

    /**
     * string ийг base64 болгох
     * @param {string} str string
     */
    b64EncodeUnicode = (str) =>
    {
        return btoa(str);
    }

    /**
     * base64 ийг string болгох
     * @param {string} str string
     */
    unicodeDecodeB64 = (str) =>
    {
        return atob(str);
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    }

    // SESSION_STATE_NAME = 'xyp_dan_state'
    // BASE_HEADERS = {
    //     'User-Agent': 'oronsuuts'
    // }

    // def __init__(self, request):
    //     self.request = request
    //     self.access_token = None
    //     self.access_token_expire_at = None

    // def is_step1(self):
    //     return not self.is_step2()

    buildRedirectUri = (payload) =>
    {
        const state = this.makeid(32)

        // Дээрх өгөгдлийг “JSON” энкодчилол хийж үр дүнг “base64” энкод хийж хадгална. Үр дүн нь:
        const scope = this.b64EncodeUnicode(JSON.stringify(payload))
        let baseUri = AUTH_URI

        if (!baseUri.endsWith('?'))
        {
            baseUri = baseUri + '?'
        }

        const params = {
            'response_type': 'code',
            'client_id': process.env.REACT_APP_CLIENT_ID,
            'redirect_uri': CALLBACK_URI,
            'scope': scope,
            'state': state,
            'login_type': 'OTP' // "OTP", "signature", "bank" гэсэн 3 хувилбартай. Default утга нь "OTP" байна. Заавал илгээх шаардлагагүй (шинэ option).
        }


        return baseUri + objectToQueryString(params)
    }

    // def is_step2(self):
    //     state = self.request.GET.get('state')
    //     auth_code = self.request.GET.get('code')
    //     return auth_code and state

    // def step2_is_state_valid(self):
    //     state = self.request.GET.get('state')
    //     session_state = self.request.session.get(self.SESSION_STATE_NAME)
    //     return state == session_state

    // def step2_fetch_access_token(self):

    //     base_uri = TOKEN_URI
    //     if not base_uri.endswith('?'):
    //         base_uri += '?'

    //     params = {
    //         'grant_type': 'authorization_code',
    //         'code': self.request.GET.get('code'),
    //         'client_id': settings.CLIENT_ID,
    //         'client_secret': base64.b64encode(settings.CLIENT_SECRET.encode()),
    //         'redirect_uri': CALLBACK_URI,
    //     }

    //     rsp = requests.post(base_uri,  data=params, headers=self.BASE_HEADERS)
    //     token_info = rsp.json()

    //     self.access_token = token_info['access_token']

    // def step3_fetch_service(self):

    //     base_uri = SERVICE

    //     headers = {
    //         **self.BASE_HEADERS,
    //         'Authorization': 'Bearer %s' % self.access_token,
    //     }

    //     rsp = requests.post(base_uri, headers=headers)
    //     if rsp.status_code == 200:
    //         return rsp.json()

    //     return '{"success": false}'

}
